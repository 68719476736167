import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // GitHub flavored markdown
import './MessageTextBox.css';



const CopyCodeButton = ({ code }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const handleCopy = () => {
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopySuccess('¡Código copiado!');
        setTimeout(() => setCopySuccess(''), 2000);
      })
      .catch(() => {
        setCopySuccess('Error al copiar');
      });
  };

  return (
    <button onClick={handleCopy} className="copy-code-button">
      {copySuccess || 'Copiar'}
    </button>
  );
};

const CodeBlock = ({ inline, children, className }) => {
    if (inline) {
      return <code className={className}>{children}</code>; // For inline code
    }
  
    // Check if the code has more than one line
    const hasMultipleLines = children.toString().includes('\n');
  
    return (
      <div className="code-block-container">
        {hasMultipleLines && (
          <div className="code-header">
            <CopyCodeButton code={children} />
          </div>
        )}
        <pre>
          <code className={className}>
            {children}
          </code>
        </pre>
      </div>
    );
  };
  

const MessageTextBox = ({ message }) => {
  return (
    <div className='message-text-box'>
      <ReactMarkdown
        children={message}
        remarkPlugins={[remarkGfm]}
        components={{
          code: CodeBlock, // Use the custom CodeBlock component
        }}
      />
    </div>
  );
};

export default MessageTextBox;
