import React, { createContext, useContext, useEffect, useReducer } from 'react';
import documentsReducer from '../reducers/documentsReducer';

// Creamos el contexto
const DocumentsContext = createContext(null);
// Definimos el endpoint de la api sin parámetros

let initialDocuments;
try {
  initialDocuments = {};
} catch {
  console.error("The preview could not be parsed into JSON");
  initialDocuments = {};
}

// Proveedor del contexto de todas las fuentes presentes en la conversación
export function DocumentsProvider(props) {
  const [documents, dispatchDocuments] = useReducer(documentsReducer, initialDocuments);
  useEffect(()=> localStorage.setItem("documents", JSON.stringify(documents)),[documents]);
  const contextValue = {
    documents,
    dispatchDocuments,
  };

  return (
    <DocumentsContext.Provider value={contextValue}>
      {props.children}
    </DocumentsContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useDocumentsContext() {
  const context = useContext(DocumentsContext);
  if (!context) {
    throw new Error(
      "useDocumentsContext must be used within a DocumentsProvider. Wrap a parent component in <DocumentsProvider> to fix this error."
    );
  }
  return context;
}
