import React, { useEffect, useState } from 'react';
import './DocumentPreview.css'; 
import { usePreviewContext } from '../../../contexts/previewContext';
import getSourceIcon from '../../../services/helpers/getSourceIcon';
import { type } from '@testing-library/user-event/dist/cjs/utility/type.js';

const DocumentPreview = ({ doc }) => {
  const { doc_id, metadata, content } = doc;
  const [isExpanded, setIsExpanded] = useState(false); // Estado para controlar la expansión
  const { preview, dispatchPreview } = usePreviewContext();
  const [isSelected, setIsSelected] = useState(false);
  const iconSrc = getSourceIcon(metadata.source);

  useEffect(() => {
    if (doc_id && preview[doc_id]) {
      if (preview[doc_id].selected) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    }
  }, [preview[doc_id]]);

  // Función para manejar el cambio de estado del checkbox
  const handleCheckboxChange = (doc) => {
    if (isSelected) {
      dispatchPreview({ type: 'unselect', selectedDoc: doc });
    } else {
      dispatchPreview({ type: 'select', selectedDoc: doc });
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatMetadataValue = (key, value) => {
    const isUrl = key.toLowerCase().includes("url");
    if (isUrl) {
      const formattedUrl = typeof value === 'string' && !/^https?:\/\//i.test(value)
        ? `https://${value}`
        : value;
      return <a href={formattedUrl} target="_blank" rel="noopener noreferrer">{formattedUrl}</a>;
    }
    return <span>{value}</span>;
  };

  const metadataKeys1 = [];
  const metadataKeys2 = ["study_type","url","authors", "doi", "pmid","keywords"];

  const renderMetadataList = (keys) => (
    <div className="metadata">
      {keys.map((key) => (
        <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '5px' }}>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>
          {formatMetadataValue(key, metadata[key])}
        </div>
      ))}
    </div>
  );

  return (
    <div className={`document-preview ${isSelected ? 'selected' : ''}`}>
      <div className="document-preview-header">
        <label className="custom-checkbox-container">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={isSelected}
            onChange={() => handleCheckboxChange(doc)}
          />
          <span className="checkmark"></span>
        </label>
        <img src={iconSrc} alt={metadata.source} className="document-icon" />
        <p className="document-source">{metadata.source}</p>
      </div>

      <h2>{metadata.title || metadata.id || doc_id}</h2>

      {renderMetadataList(metadataKeys1)}

      {isExpanded && renderMetadataList(metadataKeys2)}

      <div className="document-preview-row">
        <button className="document-button" onClick={toggleExpand}>
          {isExpanded ? 'Ver menos' : 'Ver contenido'}
        </button>

        <div className={`document-preview-content ${isExpanded ? 'expanded' : 'collapsed'}`}>
          {/* {isExpanded && (
            <p>
              <iframe src="https://www.rd.usda.gov/sites/default/files/pdf-sample_0.pdf"></iframe>
            </p>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default DocumentPreview;