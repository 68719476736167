import { API_V1_URL } from "./api-urls";

export const fetchVerifyAuthToken = async () => {
    try {
        const response = await fetch(`${API_V1_URL}/token/verify/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to verify token');
        }

        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
};
