import React, { useState } from 'react';
import './MoreOptionsButton.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import iconComment from '../../../assets/images/icons/icon-comment.svg';
import iconReport from '../../../assets/images/icons/icon-report.svg';
import iconShare from '../../../assets/images/icons/icon-share.svg';
import iconFav from '../../../assets/images/icons/icon-fav.svg';

const MoreOptionsButton = ({documents}) => {
  const [optionsVisible, setOptionsVisible] = useState(false);

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };
  return (
    <div className="more-options-button">
      <button onClick={toggleOptions} className="more-button">
        <FontAwesomeIcon className="more-options-button-icon" icon={faEllipsisV} />
      </button>

      {optionsVisible && (
        <div className="options-menu">
          <div className="option">
            <img src={iconShare} alt="Más larga" className="icon-option" />
            <span>Compartir</span>
          </div>
          <div className="option">
            <img src={iconComment} alt="Más larga" className="icon-option" />
            <span>Comentar</span>
          </div>
          <div className="option">
            <img src={iconReport} alt="Más larga" className="icon-option" />
            <span>Reportar</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MoreOptionsButton;
