import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useRedirect = () => {
  const navigate = useNavigate();

  const redirectTo = useCallback((path) => {
    navigate(path);
  }, [navigate]);

  return redirectTo;
};

export default useRedirect;
