import React, { useState } from 'react';
import './ModifyResponseButton.css'; 
import iconGenerate from '../../../assets/images/icons/icon-generate.svg';
import iconMasCorto from '../../../assets/images/icons/icon-mas-corto.svg';
import iconMasLargo from '../../../assets/images/icons/icon-mas-largo.svg';
import iconMasCasual from '../../../assets/images/icons/icon-mas-casual.svg';
import iconMasProfesional from '../../../assets/images/icons/icon-mas-profesional.svg';
import iconMasSimple from '../../../assets/images/icons/icon-mas-simple.svg';

const ModifyResponseButton = () => {
  const [optionsVisible, setOptionsVisible] = useState(false);

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  return (
    <div className="modify-response-button">
      <button onClick={toggleOptions} className="modify-button">
        <img src={iconGenerate} alt="Más corta" className="modify-response-button-icon" />
      </button>

      {optionsVisible && (
        <div className="options-menu">
          <div className="option">
            <img src={iconMasCorto} alt="Más corta" className="icon-option" />
            <span>Más corta</span>
          </div>
          <div className="option">
            <img src={iconMasLargo} alt="Más larga" className="icon-option" />
            <span>Más larga</span>
          </div>
          <div className="option">
            <img src={iconMasSimple} alt="Más simple" className="icon-option" />
            <span>Más simple</span>
          </div>
          <div className="option">
            <img src={iconMasCasual} alt="Más casual" className="icon-option" />
            <span>Más casual</span>
          </div>
          <div className="option">
            <img src={iconMasProfesional} alt="Más profesional" className="icon-option" />
            <span>Más profesional</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModifyResponseButton;