import React, { createContext, useContext, useEffect, useReducer } from 'react';
import currentMessageReducer from '../reducers/currentMessageReducer';

// Creamos el contexto
const CurrentMessageContext = createContext(null);
// Definimos el endpoint de la api sin parámetros

let initialMessage;
try {
  initialMessage = {};
} catch {
  console.error("The preview could not be parsed into JSON");
  initialMessage = {};
}

// Proveedor del contexto de todas las fuentes presentes en la conversación
export function CurrentMessageProvider(props) {
  const [currentMessage, dispatchCurrentMessage] = useReducer(currentMessageReducer, initialMessage);
  useEffect(()=> localStorage.setItem("currentMessage", JSON.stringify(currentMessage)),[currentMessage]);
  const contextValue = {
    currentMessage,
    dispatchCurrentMessage,
  };

  return (
    <CurrentMessageContext.Provider value={contextValue}>
      {props.children}
    </CurrentMessageContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useCurrentMessageContext() {
  const context = useContext(CurrentMessageContext);
  if (!context) {
    throw new Error(
      "useCurrentMessageContext must be used within a CurrentMessageProvider. Wrap a parent component in <CurrentMessageProvider> to fix this error."
    );
  }
  return context;
}
