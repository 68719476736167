import React, { useState } from 'react';
import './LikeButton.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as solidThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as regularThumbsUp } from '@fortawesome/free-regular-svg-icons';

const LikeButton = () => {
  const [liked, setLiked] = useState(false);

  const handleLike = () => {
    setLiked(!liked);
  };

  return (
    <button onClick={handleLike} className={`like-button ${liked ? 'liked': ''}`}>
      <FontAwesomeIcon className="like-button-icon" icon={liked ? solidThumbsUp : regularThumbsUp} />
    </button>
  );
};

export default LikeButton;
