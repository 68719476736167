import getSourceIcon from '../../../services/helpers/getSourceIcon';
import './CompactedDocumentElement.css';

const CompactedDocumentElement = ({ doc }) => {
    const source = doc.metadata?.source || doc.metadatas?.source || 'Fuente desconocida';
    const iconSrc = getSourceIcon(source);
  
    return (
        <div key={doc.index} className="compacted-document">
          <img src={iconSrc} alt={source} className="compacted-document-icon" />
        </div>
      );
      
  };
  
  export default CompactedDocumentElement