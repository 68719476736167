import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { fetchVerifyAuthToken } from "../../../services/api/verifyAuthToken";

export const ProtectedRoutes = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // `null` means loading

    useEffect(() => {
        const checkAuthentication = async () => {
            const isValid = await fetchVerifyAuthToken();
            setIsAuthenticated(isValid);
        };

        checkAuthentication();
    }, []);

    if (isAuthenticated === null) {
        return <div>Loading...</div>; // or a spinner/loading indicator
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace={true} />;
    }

    return <Outlet />;
};
