import React from 'react';
import './MessageToolbar.css';
import LikeButton from '../../buttons/LikeButton/LikeButton';
import NoLikeButton from '../../buttons/NoLikeButton/NoLikeButton';
import ModifyResponseButton from '../../buttons/ModifyResponseButton/ModifyResponseButton';
import NewBookmarkButton from '../../buttons/NewBookmarkButton/NewBookmarkButton';
import CopyMessagekButton from '../../buttons/CopyMessagekButton/CopyMessageButton';
import MoreOptionsButton from '../../buttons/MoreOptionsButton/MoreOptionsButton';
import CiteDocumentsButton from '../../buttons/CiteDocumentsButton/CiteDocumentsButton';

const MessageToolbar = ({ message, documents, conversation_id, message_id }) => {
  return (
    <div className="actions-button-container">
      <LikeButton message={message} />
      <NoLikeButton message={message} />
      <ModifyResponseButton message={message} />
      <CopyMessagekButton message={message} />
      <CiteDocumentsButton documents={documents} />
      <NewBookmarkButton
        active={false}
        conversation_id={conversation_id}
        message_id={message_id}
        message_content={message}
        message_type={"ai"}
      />
      <MoreOptionsButton documents={documents} />
    </div>
  );
};

export default MessageToolbar;

