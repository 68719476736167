
import { useNavigate } from 'react-router-dom';
import apiRequest from '../services/api/apiRequest';

const useLogout = () => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      const { error: apiError } = await apiRequest(
          '/token/logout/',
          'POST',
          'include',
          'application/json',
          {}
      );
      if (apiError) {
          throw new Error(apiError.message);
      }
    } catch (error) {
        console.error('Error sending action:', error);
    }
    // TODO Eliminar UserContext
    navigate('/');
  };
  return logout;
};

export default useLogout;
