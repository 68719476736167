import React from 'react';
import AiMessage from '../AiMessage/AiMessage';
import HumanMessage from '../HumanMessage/HumanMessage';
import BookmarkMessage from '../BookmarkMessage/BookmarkMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

import './MessageBox.css'

const MessageBox = ({ id, type, message, message_id, metadata, bookmark_id, conversation_id, created }) => {
  
  if (type == "ai") {
    return (
      <div className="message-box-container">
        <AiMessage id={id} message={message} metadata={metadata} conversation_id={conversation_id}/>
      </div>
    );
  }
  
  if (type == "human") {
    return (
      <div className="message-box-container">
        <HumanMessage id={id} message={message}/>
      </div>
    );
  }

  if (type == "bookmark") {
    return (
      <div className="message-box-container">
        <BookmarkMessage id={id} message_id={message_id} bookmark_id={bookmark_id} conversation_id={conversation_id} message={message} created={created}/>
      </div>
    );
  }

  if (type == "error") {
    return (
      <div className="message-box-container">
        <ErrorMessage message={message}/>
      </div>
    );
  }
};

export default MessageBox;
