import React, { useState } from 'react';
import CompactedDocumentElement from '../CompactedDocumentElement/CompactedDocumentElement';
import './DocumentGroup.css'; // Add custom styles if necessary

const documentElementLimit = 6

const DocumentGroup = ({ documents }) => {

  // Display the first 6 documents by default, or all documents if `showAll` is true
  const visibleDocuments = documents.slice(0, documentElementLimit);


  return (
    <div className="document-group">
      <div className="document-group-elements">
        {visibleDocuments.map((doc) => (
          <CompactedDocumentElement key={doc.index} doc={doc} />
        ))}
      </div>
      <div className='document-group-text'>
        {documents.length > documentElementLimit && (
            <b>Ver {documents.length} más</b>
        )}
      </div>

      
    </div>

  );
};

export default DocumentGroup;
