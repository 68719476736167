import Footer from '../../components/containers/Footer/Footer';
import Welcome from '../../components/containers/Welcome/Welcome';
import './Home.css';

function Home() {
  return (
    <div className="App">
      <Welcome></Welcome>
      <Footer></Footer>
    </div>
  );
}

export default Home;