import React from 'react';
import './DocumentCarrousel.css';
import DocumentElement from '../DocumentElement/DocumentElement';
import DocumentGroup from '../DocumentGroup/DocumentGroup'; // Import the new component

const generateMultipleDocs = (documents) => {
    const generatedDocs = [];
  
    documents.forEach((doc) => {
      for (let i = 1; i <= 1; i++) {
        // Clone the document and adjust properties if necessary (e.g., index or id)
        const newDoc = {
          ...doc,               // Spread the existing doc properties
          index: `${doc.index}-${i}`, // Modify the index to make it unique
          metadata: {
            ...doc.metadata,
            title: `${doc.metadata?.title || 'Sin título'} (${i})`, // Add a suffix to the title
          },
        };
        generatedDocs.push(newDoc);
      }
    });
  
    return generatedDocs;
  };

const DocumentCarrousel = ({ message, documents }) => {
  // ELIMINAR TRAS MAQUETADO
  // documents = generateMultipleDocs(documents) // SOLO PARA MAQUETACIÓN
  // FIN ELIMINAR TRAS MAQUETADO
  const visibleDocuments = documents.slice(0, 4); // Show the first 5 documents
  const remainingDocuments = documents.slice(4);  // Group the remaining documents

  return (
    <div className="breadcrumb-container">
      {visibleDocuments.map((doc) => (
        <DocumentElement key={doc.index} message={message} doc={doc} />
      ))}
      
      {remainingDocuments.length > 0 && (
        <DocumentGroup documents={remainingDocuments} />
      )}
    </div>
  );
};

export default DocumentCarrousel;
