import React from 'react';
import ButtonWelcome from '../../buttons/ButtonWelcome/ButtonWelcome';
import './Welcome.css';
import useRedirect from '../../../hooks/useRedirect';

const Welcome = () => {

  const redirectTo = useRedirect();

  const handleButtonClick = () => {
    redirectTo('/chat');
  };

  return (
    <div className="welcome-container">
      <img 
        src={require('../../../assets/images/logo-temp-pomelo.png')} 
        alt="Pomelo Logo" 
        className="welcome-logo" 
      />
      <h1 className="welcome-title">
        Encuentra rápidamente, explora profundamente.
      </h1>
      <p className="welcome-description">
        Accede a respuestas rápidas o explora detalles con facilidad en tu propio repositorio de información.
      </p>
      <ButtonWelcome text="Prueba Pomelo Ahora" onClick={handleButtonClick} />
    </div>
  );
};

export default Welcome;
