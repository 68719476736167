// El primer parámetro del reducer es el estado actual, en este caso se renombra como conversation.
export default function conversationReducer(conversation, action) {
  switch (action.type) {
    case "reset": 
        return {id:"", history:[]};

        case "add": {
          const lastIndex = conversation.history.length > 0 ? conversation.history[conversation.history.length - 1].index : 0;
          const messageToAdd = { index: lastIndex+1, ...action.message };            
          // Retornar un nuevo estado inmutable sin modificar el original
          const updatedConversation = {
            ...conversation, // Copia del estado actual
            history: [...conversation.history, messageToAdd], // Copia del historial con el nuevo mensaje
          };
          return updatedConversation; // Devuelve la copia modificada del estado
        }

        case "set": {
          return action.conversation ;
        }

        default:
        throw new Error("Unhadled action" + action.type);
  }
}