import './Auth.css';
import LoginForm from '../../components/forms/LoginForm/LoginForm';

function Auth() {
  return (
    <div className="auth">
      <LoginForm />
    </div>
  );
}

export default Auth;