import React from 'react';
import { FaBars } from 'react-icons/fa';
import './ToggleSidebarButton.css';
import { ReactComponent as IconSidebar } from '../../../assets/images/icons/icon-sidebar.svg';

const ToggleSidebarButton = ({ onClick, isSidebarVisible }) => {
  return (
    <button className="toggle-sidebar-button" onClick={onClick}>
      {isSidebarVisible ? <IconSidebar /> : <IconSidebar />}
    </button>
  );
};

export default ToggleSidebarButton;
