import React, { useEffect, useState } from 'react';
import './Chat.css';
import Sidebar from '../../components/containers/Sidebar/Sidebar';
import Header from '../../components/containers/Header/Header';
import ChatArea from '../../components/containers/ChatArea/ChatArea';
import PreviewArea from '../../components/containers/PreviewArea/PreviewArea';
import ChatInput from '../../components/forms/ChatInput/ChatInput';
import useChat from '../../hooks/useChat';
import { useParams } from 'react-router-dom';
import { usePreviewContext } from '../../contexts/previewContext';
import { useConversationContext } from '../../contexts/conversationContext';

const Chat = () => {
  const { id } = useParams();
  const { messages, loadingConversation, loadingMessage, addMessage, fetchMessages } = useChat();
  const { preview } = usePreviewContext();
  const { conversation } = useConversationContext();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [newMessages, setMessages] = useState(messages); // Estado local para manejar los mensajes

  useEffect(() => {
    if (id) {
      fetchMessages(id); // Cargar mensajes iniciales
    }
  }, [id]);

  useEffect(() => {
    // Verifica si preview es un objeto y tiene propiedades
    const isPreviewObject = typeof preview === 'object' && preview !== null && !Array.isArray(preview);
    const isPreviewArray = Array.isArray(preview) && preview.length > 0;
  
    if (isPreviewObject || isPreviewArray) {
      setIsSidebarVisible(false);  // Oculta el sidebar si preview es un objeto con propiedades o un array no vacío
    } else {
      setIsSidebarVisible(true);   // Muestra el sidebar si preview es un objeto vacío o un array vacío
    }
  }, [preview]);

  // Sincronizar los mensajes locales con conversation.history
  useEffect(() => {
    setMessages(conversation.history); // Actualiza los mensajes con el historial de la conversación
  }, [conversation.history]);

  const toggleSidebar = () => {
    setIsSidebarVisible(prev => !prev);
  };
  
  return (
    <div className={`chat-container ${isSidebarVisible ? '' : 'sidebar-hidden'}`}>
      <Sidebar toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
      <div className={`chat-content ${!isSidebarVisible ? 'full-width' : ''}`}>
        <Header toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} subtitle={id} />
        <div className={`chat-preview-container ${!preview ? 'full-width' : ''}`}>
          <ChatArea conversation_id={id} messages={newMessages} loading={loadingConversation} />
        </div>
        {Object.keys(preview).length === 0 ? (
            <ChatInput onSend={addMessage} loading={loadingMessage} disabled={false}/>
          ) : (
            <ChatInput onSend={addMessage} loading={loadingMessage} disabled={true} />
          )}
      </div>
      {preview ? <PreviewArea /> : <div className="preview-area hidden" />}
    </div>
  );
};

export default Chat;
