import React, { useState, useEffect } from 'react';
import './PreviewArea.css';
import { usePreviewContext } from '../../../contexts/previewContext';
import { useDocumentsContext } from '../../../contexts/documentsContext';
import DocumentPreview from '../../previews/DocumentPreview/DocumentPreview';
import ActionHookButton from '../../buttons/ActionHookButton/ActionHookButton';
import { RiCloseFill } from "react-icons/ri";
import { useCurrentMessageContext } from '../../../contexts/currentMessageContext';
import capitalize from '../../../services/helpers/capitalizeText';
import useChat from '../../../hooks/useChat';
import PreviewInput from '../../forms/PreviewInput/PreviewInput';

const PreviewArea = () => {
  const { messages, loadingMessage, addMessage } = useChat();
  const { preview, dispatchPreview } = usePreviewContext();
  const { documents } = useDocumentsContext();
  const { currentMessage, dispatchCurrentMessage } = useCurrentMessageContext();
  const [allSelected, setAllSelected] = useState(true);
  const [showPreviewInput, setShowPreviewInput] = useState(false); // Nuevo estado para controlar la visibilidad del PreviewInput

  const hasItems = Object.keys(preview).length !== 0;

  const reducedDocuments = hasItems
    ? currentMessage.documents.reduce((acc, id) => {
        if (documents[id]) acc[id] = { ...documents[id] };
        return acc;
      }, {})
    : {};

  useEffect(() => {
    if (hasItems) {
      const hasAllSelected = Object.values(preview).every(doc => doc.selected === true);
      setAllSelected(hasAllSelected);
    }
  }, [preview, currentMessage]);

  const handleCloseClick = () => {
    dispatchCurrentMessage({ type: 'reset' });
    dispatchPreview({ type: 'reset' });
  };

  const handleSelectAll = () => {
    dispatchPreview({ type: 'set', selectedDocument: reducedDocuments });
    dispatchPreview({ type: allSelected ? 'unselect_all' : 'select_all' });
  };

  const handleCreateQueryClick = () => {
    setShowPreviewInput(prev => !prev); // Alterna la visibilidad del PreviewInput
  };

  if (!hasItems) return null;
  
  return (
    <div className="preview-area">
      <div className="preview-header">
        <div className="header-info">
          <p className="source-count">{Object.keys(reducedDocuments).length} fuentes</p>
          <button className="close-btn" onClick={handleCloseClick}>
            <RiCloseFill size={24} />
          </button>
        </div>
        <p className="question-title">{capitalize(currentMessage.content)}</p>
      </div>

      <div className="preview-actions">
        <button onClick={handleSelectAll} className="select-all">
          {allSelected ? 'Quitar selección' : 'Seleccionar todo'}
        </button>
        <div className="actions-sources">
          <ActionHookButton hook_type="ver" />
          <ActionHookButton hook_type="compartir" />
          <ActionHookButton hook_type="añadir_favoritos" />
          <ActionHookButton hook_type="eliminar" />
          <button className="create-query-btn" onClick={handleCreateQueryClick}>Crear consulta</button>
        </div>
      </div>

      {/* Muestra el PreviewInput si showPreviewInput es true */}
      {showPreviewInput && <PreviewInput onSend={addMessage} loading={loadingMessage} />}

      <div className="document-list">
        {Object.entries(reducedDocuments).map(([doc_id, doc]) => (
          <div key={doc_id} className="document-row">
            <DocumentPreview doc={{ ...doc, doc_id }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreviewArea;
