import React from 'react';
import LoaderImg from "../../../assets/images/logo-temp-pomelo.png"
import "./BasicLoader.css";

const BasicLoader = () => {
  return (
    <div className='loader flex flex-c'>
      <img src = {LoaderImg} alt = "loader" />
    </div>
  )
}

export default BasicLoader