import React, { useEffect } from 'react';
import './ButtonTooltip.css'; // Asegúrate de crear un archivo CSS para estilos

const ButtonTooltip = ({ message, visible }) => {
  return (
    visible && (
      <div className="button-tooltip">
        {message}
      </div>
    )
  );
};

export default ButtonTooltip;
