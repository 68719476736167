import { useState } from 'react';
import apiRequest from '../services/api/apiRequest';

const useActionHook = () => {
  const [hooks, setActionHooks] = useState([]);
  const [loading, setLoading] = useState(false); // Para manejar el estado de carga
  const [error, setError] = useState(null); // Para manejar los errores

  const sendActionHook = async (conversation_id, hook_type, hook_args) => {
    setLoading(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        const { data, error: apiError } = await apiRequest(
            '/chatbot/use_hook/',
            'POST',
            'include',
            'application/json',
            {conversation_id: conversation_id, hook_type: hook_type, kwargs: {}}
        );
        
        if (data) {
        }
        if (apiError) {
            throw new Error(apiError.message);
        }
        
        

    } catch (error) {
        console.error('Error sending action:', error);
        setError(error.message); // Establece el mensaje de error
        setLoading(false); // Inicia el estado de carga
    } finally {
      setLoading(false); // Inicia el estado de carga
    }
  };

  const fetchActionHooks = async (bookmarks) => {
    setLoading(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        setActionHooks(() => []);
        const { data, error: apiError } = await apiRequest(
            '/hook',
            'GET',
            'include',
            'application/json'
        );

        if (apiError) {
            throw new Error(apiError.message);
        }
        
        if (data && Array.isArray(data)) {
          setActionHooks(data);
        }
        

    } catch (error) {
        console.error('Error fetching hooks:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
        setLoading(false); // Termina el estado de carga
    }
  };

  return {
    hooks,
    loading,
    sendActionHook,
    fetchActionHooks,
  };
};

export default useActionHook;
