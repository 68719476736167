import React, { useState, useEffect } from 'react';
import './MessageLoader.css'; // Asegúrate de importar el CSS
import { ThreeDots } from 'react-loader-spinner';

const MessageLoader = ({ loading }) => {
  const [showIntensiveSearch, setShowIntensiveSearch] = useState(false);
  
  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setShowIntensiveSearch(true);
      }, 10000); // Cambia el texto si pasan 10 segundos de carga
    } else {
      setShowIntensiveSearch(false);
    }
    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <div>
      {loading && (
        <div className="loading-container">
          <ThreeDots visible={true} height="30" width="30" color="#e62e43" radius="9" ariaLabel="loading"/>
          <p className="loading-text">
            {showIntensiveSearch
              ? 'Se está realizando una búsqueda más intensiva...'
              : 'Escribiendo respuesta...'}
          </p>
        </div>
      )}
    </div>
  );
};

export default MessageLoader;
