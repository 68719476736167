import { API_V1_URL } from './api-urls';

// apiRequest is a function to perform HTTP requests with token authentication and error handling
const apiRequest = (url, method = 'GET', credentials = 'include', contentType = 'application/json', body = null, refreshAuthToken) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchRefreshAuthToken = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(`${API_V1_URL}/token/refresh/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    window.location.href = '/login'; // Redirige a /login si la respuesta no es OK
                    return reject(new Error('Failed to refresh token, redirecting to login.'));
                }

                const data = await response.json();
                resolve(data); // Resuelve la promesa con los datos
            } catch (err) {
                reject(err); // Rechaza la promesa en caso de error
            }
        });
    };

    const fetchData = (retry = false) => {
        return new Promise(async (resolve, reject) => {
            try {
                // Prepare the headers
                const headers = {
                    Accept: "application/json, text/plain, */*",
                    ...(contentType && { 'Content-Type': contentType }), // Add Content-Type if specified
                };

                // Perform the fetch request
                const response = await fetch(API_V1_URL + url, {
                    method,
                    headers,
                    body: body ? JSON.stringify(body) : null, // Only stringify body if it's not null
                    signal,
                    credentials,
                });

                // If response is 401, attempt to refresh the token
                if (response.status === 401) {
                    await fetchRefreshAuthToken(); // Wait for token refresh to complete
                    // Retry the request with the new token
                    return resolve(fetchData(true));
                }

                //
                if (response.status === 204) {
                    return resolve(response)
                }

                // Handle response
                const responseContentType = response.headers.get('Content-Type');

                if (responseContentType === 'application/pdf') {
                    // Si la respuesta es PDF, conviértela a un Blob
                    const blob = await response.blob();
                    const pdfUrl = URL.createObjectURL(blob); // Crea un URL temporal para el archivo Blob
                    window.open(pdfUrl, '_blank'); // Abre el PDF en una nueva pestaña
                    resolve({ data: null, error: null, status: response.status }); // Devuelve el estado
                } else if (responseContentType.includes('application/json')) {
                    // Si la respuesta es JSON, procesa los datos normalmente
                    const data = await response.json();
                    resolve({ data, error: null, status: response.status }); // Devuelve los datos y el estado
                } else {
                    // Maneja otros tipos de respuestas
                    resolve({ data: null, error: 'Unexpected content type: ' + responseContentType, status: response.status });
                }

            } catch (err) {
                // Handle abort error explicitly
                if (err.name === 'AbortError') {
                    console.log('The request was aborted');
                    resolve({ data: null, error: null, status: null }); // Return status as null for aborted requests
                } else {
                    reject(err);
                }
            } finally {
                abortController.abort(); // Cleanup abort controller
            }
        });
    };

    // Return the promise from fetchData
    return fetchData();
};

export default apiRequest;
