const getErrorMessage = (status) => {
    const errorMessages = {
        400: "Bad Request: The server could not understand the request due to invalid syntax.",
        401: "Unauthorized: Access is denied due to invalid credentials.",
        403: "Forbidden: You do not have permission to access this resource.",
        404: "Not Found: The requested resource could not be found.",
        409: "Conflict: The request could not be completed due to a conflict with the current state of the target resource.",
        500: "Internal Server Error: The server encountered an unexpected condition.",
        502: "Bad Gateway: The server was acting as a gateway or proxy and received an invalid response.",
        503: "Service Unavailable: The server is not ready to handle the request. Common causes are server overload or maintenance.",
        504: "Gateway Timeout: The server was acting as a gateway and did not receive a timely response.",
    };

    // Return the corresponding message or a default message if the status is not recognized
    return errorMessages[status] || "An unknown error occurred. Please try again.";
};

export default getErrorMessage