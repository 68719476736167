import { useEffect, useState } from 'react';
import { useDocumentsContext } from '../../../contexts/documentsContext';
import { usePreviewContext } from '../../../contexts/previewContext';
import getSourceIcon from '../../../services/helpers/getSourceIcon';
import './DocumentElement.css';
import { useCurrentMessageContext } from '../../../contexts/currentMessageContext';

const DocumentElement = ({ message, doc }) => {
    const { preview, dispatchPreview } = usePreviewContext();
    const { documents } = useDocumentsContext();
    const { dispatchCurrentMessage } = useCurrentMessageContext();
    const [isSelected, setIsSelected] = useState(false)
    
    useEffect(() => {
      if (doc.doc_id && preview[doc.doc_id]) {
        if (preview[doc.doc_id].selected) {
          setIsSelected(true);
        } else {
          setIsSelected(false);
        }
      }
    }, [preview[doc.doc_id]]);
  
 
    const handleDocumentClick = (doc) => {
      if (isSelected) {
        dispatchCurrentMessage({ type: 'set', message: message });
        dispatchPreview({ type: 'unselect', selectedDoc: doc });
      } else {
        dispatchCurrentMessage({ type: 'set', message: message });
        dispatchPreview({ type: 'select', selectedDoc: doc });
      }
    };
  
    const index = doc.doc_id ? documents[doc.doc_id]?.index || '' : '';
    const source = doc.metadata?.source || doc.metadatas?.source || 'Fuente desconocida';
    const title = doc.metadata?.title || doc.metadatas?.title || 'Sin título';
    const iconSrc = getSourceIcon(source);
  
    return (
        <div key={doc.doc_id} documentId={doc.doc_id} className="breadcrumb-item">
          <div className={`document-element ${isSelected ? 'element-selected' : ''}`} onClick={() => handleDocumentClick(doc)}>
            <div className='document-row'>
              <div className='icon-source'>
                <img src={iconSrc} alt={source} className="document-icon" />
                <p className="document-source">{source}</p>
              </div>
              <strong className="document-index">{index}</strong>
            </div>
            <div className='document-row'>
              <p className="document-title">{title.slice(0, 60)+"..."}</p>
            </div>
          </div>
        </div>
      );
      
  };

export default DocumentElement