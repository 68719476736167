import React, { useRef, useEffect } from 'react';
import './ChatArea.css';
import MessageBox from '../../messages/MessageBox/MessageBox';
import BasicLoader from '../../loaders/BasicLoader/BasicLoader';
import { useLocation } from 'react-router-dom'; // Hook para obtener la ubicación actual (incluye query string)

const ChatArea = ({ conversation_id, messages, loading }) => {
  const chatEndRef = useRef(null); // Crea una referencia para el final del contenedor
  const location = useLocation(); // Hook para obtener la ubicación actual (incluye query string)
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      message_id: searchParams.get('message_id'),
    };
  };

  useEffect(() => {
    // Solo se ejecuta después de que los mensajes se han cargado
    if (!loading && messages && messages.length > 0) {
      const { message_id } = getQueryParams(); // Obtenemos el message_id de la query string
      
      if (message_id) {
        const message = document.querySelector(`[messageId="${message_id}"]`);
        if (message) {
          message.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      } else {
        // Si no hay un message_id específico, hacemos scroll hasta el final
        if (chatEndRef.current) {
          chatEndRef.current.scrollIntoView({ behavior: 'smooth' }); // Desplaza suavemente hacia el final
        }
      }

    }
  }, [messages, location.search, loading]); // Se ejecuta cada vez que `messages` o la query string cambian
  
  return (
    <div className="chat-area">
      {loading || !messages ? (
        <BasicLoader></BasicLoader>
      ) : (
        <>
          {messages.map((msg, index) => (
            <MessageBox key={index} conversation_id={conversation_id} id={msg.msg_id} metadata={msg.msg_metadata} type={msg.msg_type} message={msg.msg_content}  />
          ))}
          {/* Elemento de referencia para el scroll */}
          <div ref={chatEndRef} />
        </>
      )}
    </div>
  );
};

export default ChatArea;
