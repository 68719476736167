import React, { createContext, useContext, useEffect, useReducer } from 'react';
import historyReducer from '../reducers/historyReducer';

// Creamos el contexto
const HistoryContext = createContext(null);
// Definimos el endpoint de la api sin parámetros

let initialHistory;
try {
  initialHistory = {};
} catch {
  console.error("The preview could not be parsed into JSON");
  initialHistory = {};
}

// Proveedor del contexto de todas las fuentes presentes en la conversación
export function HistoryProvider(props) {
  const [history, dispatchHistory] = useReducer(historyReducer, initialHistory);
  useEffect(()=> localStorage.setItem("history", JSON.stringify(history)),[history]);

  const contextValue = {
    history,
    dispatchHistory,
  };

  return (
    <HistoryContext.Provider value={contextValue}>
      {props.children}
    </HistoryContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useHistoryContext() {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error(
      "useHistoryContext must be used within a HistoryProvider. Wrap a parent component in <HistoryProvider> to fix this error."
    );
  }
  return context;
}
