import React, { useState, useEffect } from 'react';
import './NewQueryForm.css';
import { RiCloseFill } from "react-icons/ri";
import iconConsulta from '../../../assets/images/icons/icon-consulta.svg';
import iconLock from '../../../assets/images/icons/icon-lock.svg';
import iconMicroscope from '../../../assets/images/icons/icon-microscope.svg';
import iconNotes from '../../../assets/images/icons/icon-notes.svg';

import useChatHistory from '../../../hooks/useChatHistory';

const NewQueryForm = ({ show, onClose }) => {
  const options = {'persist':'Guardado', 'temporal':'Temporal'}; // Opciones de feedback
  const botOptions = {'pomeloservices_general':'General', 'pomeloservices_private':'Búsqueda privada', 'pomeloservices_research':'Búsqueda académica', 'pomeloservices_worknotes':'Diario de trabajo'}; // Opciones de ChatBot

  // Inicializa el estado seleccionando la primera clave en cada opción
  const [selectedPersistanceOption, setSelectedPersistanceOption] = useState(Object.keys(options)[0]);
  const [selectedBotOption, setSelectedBotOption] = useState(Object.keys(botOptions)[0]);
  const [title, setTitle] = useState('Consulta'); // Estado para el título de la conversación
  const { newConversation } = useChatHistory();

  // Función para alternar opciones seleccionadas
  const toggleFeedbackOption = (option) => {
    setSelectedPersistanceOption(option);
  };

  const toggleBotOption = (option) => {
    setSelectedBotOption(option);
  };

  // Función para manejar el envío de feedback
  const handleFeedbackSubmit = () => {
    // Aquí puedes manejar la lógica para enviar el feedback (como una API)
    console.log("Feedback enviado:", { title, selectedBotOption, selectedPersistanceOption });
    newConversation(title, selectedBotOption, selectedPersistanceOption)
    // Cierra el modal después de enviar
    onClose();
  };

  useEffect(() => {
    // Asegura que el primer feedback-option y el primer botOption estén seleccionados al abrir el modal
    setSelectedPersistanceOption(Object.keys(options)[0]);
    setSelectedBotOption(Object.keys(botOptions)[0]);
  }, [show]); // Ejecutar cuando 'show' cambia

  return (
    <div className="modal-overlay">
      <div className="modal-consulta">
        {/* Encabezado */}
        <div className="modal-header">
          <h2>Nueva Consulta</h2>
          <button className="close-btn" onClick={onClose}>
            <RiCloseFill size={24} />
          </button>
        </div>

        {/* Cuerpo del modal */}
        <div className="modal-body">
            <div className="modal-section">
                <p className="modal-question">Título de la conversación</p>
                <p className="description-title">
                    Un buen título te ayudará a identificar fácilmente esta conversación en el futuro.
                </p>
                <textarea 
                    placeholder="Escribe un título descriptivo..." 
                    value={title}
                    onChange={(e) => setTitle(e.target.value)} // Actualiza el título en el estado
                />
            </div>
            <div className="modal-section">
                <p className="modal-question">¿Quieres que el chat sea guardado o temporal?</p>
                <div className="feedback-options-consulta">
                    {Object.keys(options).map((key) => (
                    <div
                        key={key}
                        className={`feedback-option-consulta ${selectedPersistanceOption === key ? 'selected' : ''}`}
                        onClick={() => toggleFeedbackOption(key)}
                    >
                        {options[key]}
                        {/* Descripción dentro de la caja */}
                        <p className="option-description">
                            {key === 'persist' 
                              ? 'La conversación se guardará para futuras referencias.' 
                              : 'La conversación se eliminará después de que finalices.'}
                        </p>
                    </div>
                    ))}
                </div>
            </div>
            <div className="modal-section">
                <p className="modal-question">¿Qué tipo de ChatBot quieres usar?</p>
                <div className="feedback-options-consulta">
                    {Object.keys(botOptions).map((key) => (
                    <div
                        key={key}
                        className={`feedback-option-consulta ${selectedBotOption === key ? 'selected' : ''}`}
                        onClick={() => toggleBotOption(key)}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* Muestra el icono correspondiente para cada opción */}
                            {botOptions[key] === 'General' && <img src={iconConsulta} alt="General" style={{ width: 28, height: 28 }} />}
                            {botOptions[key] === 'Búsqueda privada' && <img src={iconLock} alt="Búsqueda privada" style={{ width: 28, height: 28 }} />}
                            {botOptions[key] === 'Búsqueda académica' && <img src={iconMicroscope} alt="Búsqueda académica" style={{ width: 28, height: 28 }} />}
                            {botOptions[key] === 'Diario de trabajo' && <img src={iconNotes} alt="Diario de trabajo" style={{ width: 28, height: 28 }} />}
                            <span>{botOptions[key]}</span>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>

        {/* Footer del modal */}
        <div className="modal-footer">
          <button className="cancel-btn" onClick={onClose}>
            Cancelar
          </button>
          <button className="primary-btn" onClick={handleFeedbackSubmit}>
            Crear consulta
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewQueryForm;