// El primer parámetro del reducer es el estado actual, en este caso se renombra como preview.
export default function previewReducer(preview, action) {
  switch (action.type) {
    case "reset": 
        return {};

    case "set": {
      return Object.keys(action.selectedDocument).reduce((acc, id) => ({ ...acc, [id]: { selected: false } }), {});
    }

    case "select": {
      return {
        ...preview,
        [action.selectedDoc.doc_id]: { selected: true }
      };
    }

    case "unselect": {
      return {
        ...preview,
        [action.selectedDoc.doc_id]: { selected: false }
      };
    }
    

    case "select_all": {
      return Object.keys(preview).reduce((acc, key) => ({ ...acc, [key]: { ...preview[key], selected: true } }), {});
    }

    case "unselect_all": {
      return Object.keys(preview).reduce((acc, key) => ({ ...acc, [key]: { ...preview[key], selected: false } }), {});
    } 

    default:
        throw new Error("Unhadled action" + action.type);
  }
}