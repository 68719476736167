// Función para construir citas a partir de documentos
const buildCitations = (documents) => {
    return documents.map((doc, index) => {
      const { metadata } = doc; // Obtener los metadatos
  
      // Construir la cita básica
      const citation = `[${index + 1}] ${metadata.title || 'Títol no disponible'} - ${metadata.author || 'Autor no disponible'}`;
      
      // Crear un array para metadatos opcionales
      const optionalParts = [];
      if (metadata.year) optionalParts.push(` (${metadata.year})`);
      if (metadata.source) optionalParts.push(`. Font: ${metadata.source}`);
      if (metadata.id) optionalParts.push(`. Identificador: ${metadata.id}`);
      if (metadata.url) optionalParts.push(`. Disponible online a: ${metadata.url}`);
  
      // Unir la cita con los metadatos opcionales
      return [citation, ...optionalParts].join('');
    }).join('\n'); // Unir todas las citas con un salto de línea
  };
  
export default buildCitations