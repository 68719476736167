import React from 'react';
import './HumanMessage.css';

const HumanMessage = ({ id, message }) => {
    return (
      <div messageId={id} className="human-message-box">
        <p>{message}</p>
      </div>
    );
};

export default HumanMessage;
