import { useState } from 'react';
import { BiCommentDetail } from 'react-icons/bi';
import apiRequest from '../services/api/apiRequest';
import formatDate from '../services/helpers/formatDate';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useHistoryContext } from '../contexts/historyContext';

const useChatHistory = () => {
    const { id } = useParams(); // Extrae el parámetro :id de la URL
    const { history, dispatchHistory } = useHistoryContext()
    const [loading, setLoading] = useState(false); // Para manejar el estado de carga
    const [error, setError] = useState(null); // Para manejar los errores
    const navigate = useNavigate();
    const location = useLocation();
    
    const newConversation = async (name, chatbot, persist) => {
        setLoading(true); // Inicia el estado de carga
        setError(null); // Limpia cualquier error previo
        debugger
        try {
            const { data, error: apiError } = await apiRequest(
                '/conversations/',
                'POST',
                'include',
                'application/json',
                { name: name, chatbot: chatbot } // APLICAR MEDIANTE LOS DATOS USUARIO
            );

            if (apiError) {
                throw new Error(apiError.message);
            }

            // setHistory([{ id: data.id, text: `${data.name} - ${formatDate(data.created)}`, icon: BiCommentDetail },...history]);
            dispatchHistory({type:'add', data })
            navigate(`/chat/${data.id}`)
            setLoading(false); // Termina el estado de carga
        } catch (error) {
            console.error('Error adding message:', error);
            setError(error.message); // Establece el mensaje de error
        } finally {
            setLoading(false); // Termina el estado de carga
        }
    };

    const listConversations = async () => {
        setLoading(true); // Inicia el estado de carga
        setError(null); // Limpia cualquier error previo

        try {
            const { data, error: apiError } = await apiRequest('/conversations/', 'GET', 'include', 'application/json',null);
            
            if (apiError) {
                throw new Error(apiError.message);
            }
            dispatchHistory({type:'set', data })
            if (location.pathname === '/chat' && !id ) {
                navigate(`/chat/${data[0].id}`)
            }
        } catch (error) {
            console.error('Error adding message:', error);
            setError(error.message); // Establece el mensaje de error
        } finally {
            setLoading(false); // Termina el estado de carga

        }
    };

    const deleteConversation = async (id) => {
        setLoading(true); // Inicia el estado de carga
        setError(null); // Limpia cualquier error previo
    
        try {
            const response = await apiRequest(`/conversations/${id}`, 'DELETE', 'include', 'application/json', null);
    
                const { error: apiError } = response;
    
            if (apiError) {
                throw new Error(apiError.message);
            }    
            dispatchHistory({type:'remove', id })

        } catch (error) {
            console.error('Error deleting message:', error);
            setError(error.message); // Establece el mensaje de error
        } finally {
            setLoading(false); // Termina el estado de carga
        }
    };
    
    return {
        newConversation,
        listConversations,
        deleteConversation,
        loading,
        error
    };
};

export default useChatHistory;
