import React, { createContext, useContext, useEffect, useReducer } from 'react';
import conversationReducer from '../reducers/conversationReducer';

// Creamos el contexto
const ConversationContext = createContext(null);
// Definimos el endpoint de la api sin parámetros

let initialConversation;
try {
  initialConversation = {};
} catch {
  console.error("The preview could not be parsed into JSON");
  initialConversation = {};
}

// Proveedor del contexto de todas las fuentes presentes en la conversación
export function ConversationProvider(props) {
  const [conversation, dispatchConversation] = useReducer(conversationReducer, initialConversation);
  useEffect(()=> localStorage.setItem("conversation", JSON.stringify(conversation)),[conversation]);
  const contextValue = {
    conversation,
    dispatchConversation,
  };

  return (
    <ConversationContext.Provider value={contextValue}>
      {props.children}
    </ConversationContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useConversationContext() {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error(
      "useConversationContext must be used within a ConversationProvider. Wrap a parent component in <ConversationProvider> to fix this error."
    );
  }
  return context;
}
