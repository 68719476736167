

import React from 'react';
import useChatHistory from '../../../hooks/useChatHistory';
import NewQueryButtonFullScreen from '../../buttons/NewQueryButtonFullScreen/NewQueryButtonFullScreen';
import ToggleSidebarButtonFullScreen from '../../buttons/ToggleSidebarButtonFullScreen/ToggleSidebarButtonFullScreen';
import './Header.css';
import ActionHookButton from '../../buttons/ActionHookButton/ActionHookButton';

const Header = ({ toggleSidebar, isSidebarVisible, subtitle }) => {
  const { loading } = useChatHistory();

  return (
    <header className="header">
      {isSidebarVisible ? (
        <h1 className="header-title"></h1>
      ) : (
        <div className="sidebar-toggle-container">
          <ToggleSidebarButtonFullScreen
            onClick={toggleSidebar}
            isSidebarVisible={isSidebarVisible}
          />
          <NewQueryButtonFullScreen loading={loading} />
        </div>
      )}
      {/*<div className="logo">
        <img
          src={require('../../../assets/images/logo-temp-pomelo.png')}
          alt="Pomelo Logo"
          className="header-logo"
        />
        <h2 className="header-title">Pomelo</h2>
      </div>
      {subtitle && <h2 className="header-subtitle">{subtitle}</h2>}*/}

      {/* Botón que llama al hook sendActionHook */}
      <div className="action-button-container">
        <ActionHookButton hook_type="print_conversation"/>
        <ActionHookButton hook_type="compartir"/>
        <ActionHookButton hook_type="añadir_favoritos"/>
        <ActionHookButton hook_type="eliminar"/>
      </div>
    </header>
  );
};

export default Header;
