const formatDate = (isoDate) => {
    const date = new Date(isoDate);
  
    // Extraer las partes de la fecha
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Los meses son base 0
    const year = date.getUTCFullYear();
  
    // Extraer las partes de la hora
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Formato final
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

export default formatDate;
