import React, { useState } from 'react';
import './NewQueryButtonFullScreen.css';
import { FaPlus } from 'react-icons/fa';
import NewQueryForm from '../../forms/NewQueryForm/NewQueryForm';

const NewQueryButtonFullScreen = ({ loading }) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
  
    return (
    <>
        <button 
            className={`new-query-button-FullScreen ${loading ? 'loading':''}`}
            disabled={loading}
            onClick={handleOpenModal}
        >
            <FaPlus className="icon" />
        </button>
        {showModal && (
        <NewQueryForm show={showModal} onClose={handleCloseModal} />
        )}
    </>

    );
};

export default NewQueryButtonFullScreen;
