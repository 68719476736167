import React, { useState } from 'react';
import './ChatInput.css';
import { FiSend } from 'react-icons/fi';
import MessageLoader from '../../loaders/MessageLoader/MessageLoader';

const ChatInput = ({ onSend, loading, disabled=false }) => {
  const [input, setInput] = useState('');
  const [intention, setIntention] = useState('rag_question');
  const [kwargs, setKwargs] = useState({
    doc_ids: [], // Aseguramos que esto sea un array
    metadatas: {},
    related_message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() && intention.trim()) {
      onSend(input, intention, kwargs);
      setInput(''); // Resetear input
      setKwargs({
        doc_ids: [], // Resetear doc_ids como array
        metadatas: {},
        related_message: "",
      });
    }
  };

  return (
    <div className="chat-input-container">
      <MessageLoader loading={loading}/>
      <form className="chat-input-form" onSubmit={handleSubmit}>
        <div className="input-row">
          {/* <select
            id="intention-select"
            className="intention-select"
            value={intention}
            onChange={(e) => setIntention(e.target.value)}
            disabled={loading || disabled}
          >
            <option value="rag_question">Búsqueda</option>
            <option value="doc_question">Consultar documento</option>
          </select> */}

          <input
            id="input-message"
            type="text"
            className="chat-input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Escribe tu consulta aquí..."
            disabled={loading || disabled}
          />
          <button type="submit" className="chat-send-btn" disabled={loading || disabled}>
            <FiSend />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatInput;
