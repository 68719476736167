import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/index.css';
import reportWebVitals from './reportWebVitals';
import { AppRouter } from './routes';
import { PreviewProvider } from './contexts/previewContext';
import { DocumentsProvider } from './contexts/documentsContext';
import { CurrentMessageProvider } from './contexts/currentMessageContext';
import { ConversationProvider } from './contexts/conversationContext';
import { HistoryProvider } from './contexts/historyContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HistoryProvider>
    <PreviewProvider>
    <DocumentsProvider>
    <ConversationProvider>
    <CurrentMessageProvider>
      <AppRouter />
    </CurrentMessageProvider>
    </ConversationProvider>
    </DocumentsProvider>
    </PreviewProvider>
    </HistoryProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
