export const getTodayDate = () => {
    const today = new Date();
    return {
      day: today.getDate(),
      month: today.getMonth(),
      year: today.getFullYear(),
      week: getWeekNumber(today),
    };
  };
  
  export const getWeekNumber = (date) => {
    const start = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date - start) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + start.getDay() + 1) / 7);
  };
  
  export const groupMessagesByMonth = (messages) => {
    return messages.reduce((groups, message) => {
      const createdDate = new Date(message.created);
      const monthYear = `${createdDate.getFullYear()}-${createdDate.getMonth() + 1}`; // Format: YYYY-MM
  
      if (!groups[monthYear]) {
        groups[monthYear] = [];
      }
      groups[monthYear].push(message);
      return groups;
    }, {});
  };

  export const formatMonthYear = (monthYear) => {
    const [year, month] = monthYear.split('-'); // Separamos año y mes
    const date = new Date(year, month - 1); // Creamos una fecha (mes comienza en 0)
    
    // Obtenemos el nombre completo del mes en español
    const monthName = date.toLocaleString('es-ES', { month: 'long' });
  
    // Retornamos el mes capitalizado y el año
    return `${monthName.charAt(0).toUpperCase() + monthName.slice(1)} ${year}`;
  };
  