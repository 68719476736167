import React from 'react';
import { useNavigate } from 'react-router-dom';  // Para redirigir a otra página
import { FaEye } from 'react-icons/fa';  // Ícono de ojo
import './BookmarkMessage.css';
import formatDate from '../../../services/helpers/formatDate';

const BookmarkMessage = ({ message_id, message, bookmark_id, conversation_id, created }) => {
  
  const navigate = useNavigate();

  const formatMessage = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const handleViewClick = () => {
    // Redirige a la URL con el ID del mensaje
    navigate(`/chat/${conversation_id}?message_id=${message_id}`);
  };

  return (
    <div messageId={message_id} bookmarkId={bookmark_id} conversation_id={conversation_id} className="bookmark-message-box">
      <p>{formatMessage(message)}</p>
      <div className="bookmark-header">
        <FaEye onClick={handleViewClick} className="eye-icon" title="Ver mensaje" />
        <small>{formatDate(created)}</small>
        {/* Ícono de ojo que redirige */}
      </div>
    </div>
  );
};

export default BookmarkMessage;
