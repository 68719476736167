// El primer parámetro del reducer es el estado actual, en este caso se renombra como documents.
export default function documentsReducer(documents, action) {
    switch (action.type) {
        case "reset": 
            return [];

            case "add": {
              const newDocuments = extractDocumentsFromMessage([action.responseMessage] || [])
              return { ...documents, ...newDocuments };
            }

            case "set": {
              const documents = extractDocumentsFromHistory(action.history)
              return documents;
            }

            default:
            throw new Error("Unhadled action" + action.type);
    }
}

function extractDocumentsFromHistory(list) {
  let documentsDict = {};
  let index = 1; // Inicializamos el índice desde 1

  list.forEach(item => {
    let documentsInMsg = item.msg_metadata.documents;

    // Verificamos que `documentsInMsg` sea un array
    if (Array.isArray(documentsInMsg)) {
      documentsInMsg.forEach(doc => {
        // Solo agregamos el documento si aún no está en el diccionario
        if (doc.doc_id && !documentsDict[doc.doc_id]) {
          const newDoc = {
            content: doc.document,
            metadata: doc.metadata
          };
          documentsDict[doc.doc_id] = newDoc; // Asignamos el documento
          documentsDict[doc.doc_id].index = index++; // Ahora asignamos el índice
        }
      });
    }
  });

  return documentsDict; // Retornamos el diccionario de documentos
}

function extractDocumentsFromMessage(list) {
  let documentsDict = {};
  let index = 1; // Inicializamos el índice desde 1

  list.forEach(item => {
    let documentsInMsg = item.msg_metadata.documents;
    // Verificamos que `documentsInMsg` sea un array
    if (Array.isArray(documentsInMsg)) {
      documentsInMsg.forEach(doc => {
        // Solo agregamos el documento si aún no está en el diccionario
        if (doc.doc_id && !documentsDict[doc.doc_id]) {
          const newDoc = {
            content: doc.document,
            metadata: doc.metadata
          };
          documentsDict[doc.doc_id] = newDoc; // Asignamos el documento
          documentsDict[doc.doc_id].index = index++; // Ahora asignamos el índice
        }
      });
    }
  });

  return documentsDict; // Retornamos el diccionario de documentos
}
