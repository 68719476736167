import React from 'react';
import './SidebarButtonFooter.css';

const SidebarButtonFooter = ({ text, Icon, active, onClick, className }) => {
    return (
            <button 
                className={`sidebar-button-footer ${active ? 'active' : ''} ${className || ''}`} 
                onClick={onClick}
            >
                {Icon && <Icon className="icon" />}
                <span>{text}</span>
            </button>
    );
};

export default SidebarButtonFooter;