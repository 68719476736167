import { useState } from 'react';
import apiRequest from '../services/api/apiRequest';
import { useParams } from 'react-router-dom';

const useBookmark = () => {
  const [bookmarks, setBookmark] = useState([]);
  const [loading, setLoading] = useState(false); // Para manejar el estado de carga
  const [error, setError] = useState(null); // Para manejar los errores

  const addBookmark = async (conversation_id, message_id, message_type, message_content) => {
    setError(null); // Limpia cualquier error previo

    try {
        const { data, error: apiError } = await apiRequest(
            '/bookmarks/',
            'POST',
            'include',
            'application/json',
            {conversation_id: conversation_id,  message_id: message_id, message_content: message_content, message_type: message_type}
        );

        if (apiError) {
            throw new Error(apiError.message);
        }

    } catch (error) {
        console.error('Error adding message:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
    }
  };

  const fetchBookmarks = async (bookmarks) => {
    setLoading(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        setBookmark(() => []);
        const { data, error: apiError } = await apiRequest(
            '/bookmarks',
            'GET',
            'include',
            'application/json'
        );

        if (apiError) {
            throw new Error(apiError.message);
        }
        
        if (data && Array.isArray(data)) {
          setBookmark(data);
        }
        

    } catch (error) {
        console.error('Error fetching bookmarks:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
        setLoading(false); // Termina el estado de carga
    }
  };

  return {
    bookmarks,
    loading,
    addBookmark,
    fetchBookmarks,
  };
};

export default useBookmark;
